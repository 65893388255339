import React from "react"
import { useIntl } from "../../utils"
import { Layout, SEO, Protected } from "../../components"
import { Callback } from "../../containers"
const Page = () => {
  const intl = useIntl()
  return (
    <Layout>
      <SEO title={intl.formatMessage({ id: "hello" })} />
      <Protected component={Callback}></Protected>
    </Layout>
  )
}
export default Page
